<template>
  <div class="d-flex align-items-center">
    <div
      ref="selectWrapper"
      @click="isOpen = !isOpen"
      :class="{
        'custom-select text-state text-state-md desktop mr-0 ml-3': true,
        active: isOpen
      }">
      <span class="selected-option">
        <div class="circle" :style="{ backgroundColor: options.find(e => e.value == agent_status)?.color || 'grey' }">
        </div>
        {{ options.find(e => e.value == agent_status)?.text || 'Selecciona un estado' }}
      </span>
      <FadeTransition v-show="isOpen" :duration="200">
        <div class="options-list">
          <div v-for="(option, index) in options.filter(e => e.value !== agent_status)" :key="option.text + index"
            @click="changeStatus(option.value)" class="option py-2">
            <div class="d-flex align-items-center">
              <div class="circle" :style="{ backgroundColor: option.color }"></div>
              <span>
                {{ option.text }}
              </span>
            </div>
            <p class="description pt-2">{{ option.description }}</p>
          </div>
        </div>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { changeAgentStatus } from "../utils/utils";
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      isOpen: false,
      options: [
        {
          text: "Conectado",
          description: "Presente en la plataforma",
          color: "#00D100",
          value: "active"
        },
        {
          text: "Ausente",
          description: "Lejos de la plataforma",
          color: "#efb810",
          value: "absent"
        },
        {
          text: "Desconectado",
          description: "Fuera de horario de atención",
          color: "#FF6565",
          value: "inactive"
        },
      ]
    }
  },
  computed: {
    ...mapState([
      'agent_status',
      'userBot',
      'selected_bot'
    ]),
  },
  mounted() {
    // Event listener para cerrar el select cuando se clickea afuera
    document.addEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapMutations([
      "setAgentStatus"
    ]),
    async changeStatus(value) {
      await changeAgentStatus(value)
    },
    handleClickOutside(event) {
      // Verificar si el clic es fuera del contenedor del select
      const selectElement = this.$refs.selectWrapper;
      if (selectElement && !selectElement.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  padding-right: 1.75rem;
  height: 100%;
  background-color: #fafafa;

  &.active {
    border-color: #2981ef;

    .selected-option {
      color: #2981ef;
    }
  }
}
.selected-option {
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-list {
  position: absolute;
  width: 240px;
  top: 100%;
  right: 0;
  padding: .5rem;
  border-radius: 1rem;
  box-shadow: 0 0 30px #D8EAFF;
  background-color: #fff;
  transition: .25s;
}

.option {
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
  border-radius: .5rem;
}

.option:hover {
  transition: .25s;
  background-color: #e8e8e8;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.description {
  color: #aaa;
  font-size: 0.7rem;
  margin-bottom: 0;
  margin-left: 20px;

}
</style>