import api from '@/services/api_micro_conversations/conversations';
import { handleError, buildQueryWithPagination, scrollBottom } from '@/utils/utils.js';
import store from '@/store/store';

const module = {
  namespaced: true,
  state: {
    selectedState: 'active',
    selectedConversation: null,
    conversations: [],
    messages: [],
    countersByState: { active: 0, pending: 0, taken: 0 },
    total: 0,
    totalPages: 0,
    conversationPages: 0,
  },
  actions: {
    list({ commit }, query) {
      let finalQuery = buildQueryWithPagination(query);
      return new Promise((resolve, reject) => {
        api
          .list(finalQuery)
          .then((response) => {
            commit('list', response.data);
            commit('totalItems', response.data.totalDocs);
            commit('totalPages', response.data.totalPages);
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    create({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .create(data)
          .then((res) => {
            commit('create', res.data);
            resolve(res.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    update({ commit }, { id, data, addConversation = false } = {}) {
      return new Promise((resolve, reject) => {
        api
          .update(id, data)
          .then((res) => {
            commit('update', {
              id,
              data: res.data,
              addConversation,
            });

            resolve(res.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(id)
          .then(() => {
            commit('delete', id);
            resolve();
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    countStates({ commit }, { botId, userId, category, tagsLead, isAdmin }) {
      return new Promise((resolve, reject) => {
        api
          .countStates(botId, userId, { category, tagsLead, isAdmin })
          .then((res) => {
            commit('countStates', res.data);
            resolve();
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    filterConversations({ commit }, { botId, agentId, filters, items, page }) {
      return new Promise((resolve, reject) => {
        api
          .filterConversations(botId, agentId, filters, { items, page })
          .then((response) => {
            commit('list', response.data.conversations);
            commit('conversationPages', response.data.pages);
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    listArchivedConversations(
      { commit },
      { botId, agentId, filters, items, page },
    ) {
      return new Promise((resolve, reject) => {
        api
          .filterConversations(botId, agentId, filters, { items, page })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    searchArchivedConversations(
      { commit },
      { bot_id, search_text, items = 15, page = 1 } = {},
    ) {
      return new Promise((resolve, reject) => {
        api
          .searchConversations(bot_id, search_text, items, page, true)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    searchConversations(
      { commit },
      { bot_id, search_text, items = 15, page = 1 } = {},
    ) {
      return new Promise((resolve, reject) => {
        api
          .searchConversations(bot_id, search_text, items, page, false)
          .then((response) => {
            commit('list', response.data.conversations);
            commit('conversationPages', response.data.pages);
            resolve(response.data);
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
      });
    },
    deleteConversationAndDecreaseCounter({ commit }, { id, state }) {
      commit('delete', id);
      commit('increaseCountersByState', { state, qty: -1 });
    },
  },
  mutations: {
    conversationPages(state, data) {
      state.conversationPages = data;
    },
    list(state, data) {
      state.conversations = data;
    },
    totalItems(state, data) {
      state.total = data;
    },
    totalPages(state, data) {
      state.totalPages = data;
    },
    create(state, data) {
      state.conversations.push(data);
    },
    update(state, { id, data, addConversation }) {
      let indexToUpdate = state.conversations.findIndex((el) => el.id == id);
      console.log('Updating with mutation...', data);
      state.conversations.splice(indexToUpdate, addConversation ? 0 : 1, {
        ...data,
      });
    },
    delete(state, id) {
      console.log('Activando delete conver...', state.conversations, id);
      let indexToDelete = state.conversations.findIndex((el) => el.id == id);
      console.log('🐞 LOG HERE indexToDelete:', indexToDelete);
      state.conversations.splice(indexToDelete, 1);
      state.total -= 1;
    },
    addConversation(state, data) {
      const index = state.conversations.findIndex(
        (conversation) => conversation.id == data.id,
      );
      if (!data.pending_messages_count) {
        data.pending_messages_count = 0;
      }
      if (index == -1 && data.state === state.selectedState) {
        state.conversations.unshift(data);
      }
    },
    // TODO no entiendo por que no funciona bien
    // se supone que debe borrar la conversación archivada
    deleteConversation(state, data) {
      let index = state.conversations.findIndex(
        (conversation) => conversation.id === data.id,
      );

      state.selectedConversation = null;
      state.conversations.splice(index, 1);
    },
    setMessages(state, data) {
      state.messages = data;
    },
    addMessage(state, data) {
      state.messages.push(data);
    },
    updateMessageByTemporalId(state, { temporalId, data }) {
      let index = state.messages.findIndex(
        (el) => el.temporal_id === temporalId,
      );
      if (index > -1) {
        state.messages.splice(index, 1, data);
      }
    },
    deleteMessage(state, data) {
      let index = state.messages.findIndex((el) => el._id === data);
      state.messages.splice(index, 1);
    },
    setSelectedConversation(state, data) {
      state.selectedConversation = data;
    },
    countStates(state, data) {
      state.countersByState = data;
    },
    setSelectedState(state, data) {
      state.selectedState = data;
    },
    increaseCountersByState(state, { state: conversationState, qty = 0 }) {
      const newValue = state.countersByState[conversationState] + qty;
      state.countersByState[conversationState] = newValue < 0 ? 0 : newValue;
    },
    setIsUserOnlineStatus(state, { conversationId, status }) {
      const conversation = state.conversations.find(
        (conversation) => conversation.id === conversationId,
      );
      if (conversation) {
        conversation.isUserOnline = status;
        console.log('La conversa actualizada: ', conversation);
      }
      // update selected conversation
      if (
        state.selectedConversation &&
        state.selectedConversation.id === conversationId
      ) {
        state.selectedConversation.isUserOnline = status;
      }
    },
  },
  getters: {
    getByConversationId: (state) => (conversationId) => {
      return state.conversations.filter(
        (conversation) => conversation.id === conversationId,
      );
    },
    getSortedConversations: (state) => {
      // separate array conversations with pending_messages_count
      // const conversationsWithPendingMessages = state.conversations
      //   .filter((conversation) => conversation.pending_messages_count > 0)
      //   .sort((a, b) => b.updatedAt - a.updatedAt);
      // const conversationsWithoutPendingMessages = state.conversations
      //   .filter((conversation) => !conversation.pending_messages_count)
      //   .sort((a, b) => b.updatedAt - a.updatedAt);
      // const splitted = [
      //   ...conversationsWithPendingMessages,
      //   ...conversationsWithoutPendingMessages,
      // ];
      // return order desc by updatedAt
      let filteredConversations = JSON.parse(
        JSON.stringify(state.conversations),
      );
      // if (store.state.agentAccount) {
      //   filteredConversations = filteredConversations.filter((el) =>
      //     this.agentAccount && this.agentAccount.category
      //       ? this.agentAccount.category.includes(el.category)
      //       : true,
      //   );
      // }
      return filteredConversations.sort(
        (a, b) => new Date(b.last_message_date) - new Date(a.last_message_date),
      );
    },
    getTotalPagesConversation: (state) => {
      return state.conversationPages;
    },
    getConversationById: (state) => (conversationId) => {
      return state.conversations.find(
        (conversation) => conversation.id === conversationId,
      );
    },
    getMessageByTemporalId: (state) => (temporalId) =>
      state.messages.find((el) => el.temporal_id === temporalId),
    checkUserOnlineByConversationId: (state) => (conversationId) => {
      const conversation = state.conversations.find(
        (conversation) => conversation.id === conversationId,
      );
      return conversation?.isUserOnline;
    },
  },
};

export default module;
